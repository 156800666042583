<template>
	<div
		class="reply-file__other"
		:class="classObj"
	>
		<el-progress
			v-if="showProgress && percentage !== 100"
			:percentage="percentage"
			:show-text="false"
			:stroke-width="1"
			class="upload-progress"
		></el-progress>
		<div class="file-info">
			<img :src="assets.file" />
			<span class="text-ellipsis"> {{ fileName }} </span>
		</div>
		<div class="file-size">
			{{ fileSize }}
		</div>

		<div v-if="action ==='download' || action === 'remove'" class="tool-wrapper">
			<i v-if="action === 'download'" class="el-icon-download" @click="download"></i>
			<i v-if="action === 'remove'" class="el-icon-close" @click="remove"></i>
		</div>
	</div>
</template>

<script>
import file from '@/assets/images/file.png'

export default {
	name: 'ReplyFile',
	props: {
		// 文件数据
		file: {
			type: Object,
			default: () => {
				return {}
			}
		},
		// large medium small
		size: {
			type: String,
			default: 'medium'
		},
		percentage: {
			type: Number,
			default: 0
		},
		// 显示进度条
		showProgress: {
			type: Boolean,
			default: false
		},
		// 显示那个功能按钮 download, remove
		action: {
			type: String
		}
	},
	computed: {
		classObj() {
			if (this.size === 'large') return 'reply-file__large'
			if (this.size === 'medium') return 'reply-file__medium'
			if (this.size === 'small') return 'reply-file__small'
			return 'reply-file__medium'
		},
		fileName() {
      if (this.file.fileName) return this.file.fileName
      if (this.file.name) return this.file.name
      return ''
    },
    fileSize() {
      if (this.file.fileSize) return this.file.fileSize

      if (!this.file.size || this.file.sie === 0) return '0'

      let size = this.file.size

      if (size < 1024) return size + 'B'

      size = Math.ceil(this.file.size / 1024)
      if (size < 1024) return size + 'K'

      size = Math.ceil(this.file.size / (1024 * 1024))
      if (size < 1024) return size + 'M'

      size = Math.ceil(this.file.size / (1024 * 1024 * 1024))
      return size + 'G'
    }
	},
	data() {
		return {
			assets: {
				file: file
			}
		}
	},
	methods: {
		download() {
			const link = document.createElement('a')
			link.href = this.file.fileAddr
			link.download = this.file.fileName
			link.click()
		},
		remove() {
			this.$emit('remove')
		}
	}
}
</script>

<style lang="scss" scoped>
.reply-file__other {
	position: relative;
	background-color: rgba(255, 255, 255, 1);
	border-radius: 6px;
	overflow: hidden;
	border: 1px solid rgba(221, 221, 221, 1);

	.file-info {
    display: flex;
    align-items: center;
  }
	.tool-wrapper {
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		background-color: rgba(0, 0, 0, .1);
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		flex-direction: column;

		i {
			cursor: pointer;
			color: rgba(0, 0, 0, .3);

			&:hover {
				color: rgba(0, 0, 0, .5);
			}
		}
	}
	.upload-progress {
		position: absolute;
		top: 0;
		left: 2.5%;
		width: 95%;
	}
}
.reply-file__other:hover {
	.tool-wrapper {
		-webkit-transition: all .3s ease;
		-moz-transition: all .3s ease;
		-o-transition: all .3s ease;
		transition: all .3s ease;
	}	
}
.reply-file__large {
	width: 180px;
	height: 50px;
	padding: 10px;

	.file-info {
    img {
      width: 27px;
      margin-right: 10px;
    }
    span {
      font-size: 12px;
    }
  }
	.file-size {
		font-size: 12px;
		padding: 6px 0 0 37px;
	}
	.tool-wrapper {
		width: 50px;
		margin-right: -50px;

		i {
			font-size: 25px;
		}
	}
}
.reply-file__large:hover {
	.tool-wrapper {
		-webkit-transform: translateX(-49px);
		-moz-transform: translateX(-49px);
		-o-transform: translateX(-49px);
		-ms-transform: translateX(-49px);
		transform: translateX(-49px);
	}
}
.reply-file__medium {
	width: 150px;
	height: 40px;
	padding: 8px;

	.file-info {
    img {
      width: 22px;
      margin-right: 8px;
    }
    span {
      font-size: 12px;
    }
  }
	.file-size {
		font-size: 12px;
		padding: 6px 0 0 30px;
	}
	.tool-wrapper {
		width: 35px;
		margin-right: -35px;

		i {
			font-size: 20px;
		}
	}
}
.reply-file__medium:hover {
	.tool-wrapper {
		-webkit-transform: translateX(-34px);
		-moz-transform: translateX(-34px);
		-o-transform: translateX(-34px);
		-ms-transform: translateX(-34px);
		transform: translateX(-34px);
	}
}
.reply-file__small {
	width: 113px;
	height: 30px;
	padding: 6px;

	.file-info {
    img {
      width: 16.5px;
      margin-right: 6px;
    }
    span {
      font-size: 12px;
    }
  }
	.file-size {
		font-size: 12px;
		padding: 4px 0 0 22.5px;
	}
	.tool-wrapper {
		width: 27px;
		margin-right: -27px;

		i {
			font-size: 15px;
		}
	}
}
.reply-file__small:hover {
	.tool-wrapper {
		-webkit-transform: translateX(-26px);
		-moz-transform: translateX(-26px);
		-o-transform: translateX(-26px);
		-ms-transform: translateX(-26px);
		transform: translateX(-26px);
	}
}
</style>
