<template>
	<div
		class="reply-file__img"
		:class="classObj"
		v-loading="showProgress && percentage !== 100"
	>
		<el-image
			:src="fileAddr"
			:preview-src-list="previewSrcList"
		>
			<div
				slot="placeholder"
				v-loading="true"
				class="image-placeholder"
			></div>
			<div slot="error" class="image-error">
        <i class="el-icon-picture-outline"></i>
      </div>
		</el-image>
		<div v-if="action === 'remove'" class="tool-wrapper">
			<i class="el-icon-close" @click="remove"></i>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ReplyImage',
	props: {
		// 图片数据
		image: {
			type: Object,
			default: () => {
				return {}
			}
		},
		response: {
			type: Object,
			default: () => {
				return {}
			}
		},
		percentage: {
			type: Number,
			default: 0
		},
		// 显示进度条
		showProgress: {
			type: Boolean,
			default: false
		},
		// 预览图中的图片链接
		previewList: {
			type: Array,
			default: () => {
				return []
			}
		},
		// large medium small mini
		size: {
			type: String,
			default: 'mini'
		},
		// 显示功能按钮 remove: 删除按钮
		action: {
			type: String
		}
	},
	computed: {
		classObj() {
			if (this.size === 'large') return 'reply-file__img__large'
			if (this.size === 'medium') return 'reply-file__img__medium'
			if (this.size === 'small') return 'reply-file__img__small'
			if (this.size === 'mini') return 'reply-file__img__mini'
			return 'reply-file__img__medium'
		},
		previewSrcList() {
			if (this.previewList.length > 0) {
				return this.previewList
			}

			if (this.fileAddr) return [this.fileAddr]

			return []
		}
	},
	watch: {
		response(res) {
			if (res && res.code === '0' && res.data && res.data.fileAddr) this.fileAddr = res.data.fileAddr
		}
	},
	created() {
		if (this.image.fileAddr) this.fileAddr = this.image.fileAddr
	},
	data() {
		return {
			fileAddr: ''
		}
	},
	methods: {
		remove() {
			this.$emit('remove')
		}
	}
}
</script>

<style lang="scss" scoped>
.reply-file__img {
	position: relative;
	// border: 1px solid rgba(0, 0, 0, .3);
	
	.image-error {
		display: flex;
		align-items: center;
		justify-content: space-around;
		text-align: center;

		i {
			color: rgba(0, 0, 0, .3);
			text-align: center;
		}
	}
	.upload-progress {
		position: absolute;
		top: 0;
		left: 2.5%;
		width: 95%;
	}
	.tool-wrapper {
		position: absolute;
		top: 0;
		right: 0;
		background-color: rgba(0, 0, 0, .1);
		display: none;
		align-items: center;
		justify-content: space-evenly;
		z-index: 2001;

		i {
			cursor: pointer;
			color: rgba(0, 0, 0, .3);

			&:hover {
				color: rgba(0, 0, 0, .5);
			}
		}
	}
	&:hover {
		.tool-wrapper {
			display: flex;
		}
	}
}
.reply-file__img__large {
	max-width: 200px;
	// padding: 10px;

	.image-placeholder, .image-error {
		width: 200px;
		height: 200px;
	}
	.image-error {
		i {
			font-size: 100px;
		}
	}
	.tool-wrapper {
		width: 22px;
		height: 22px;

		i {
			font-size: 16px;
		}
	}
}
.reply-file__img__medium {
	max-width: 140px;
	// padding: 8px;

	.image-placeholder, .image-error {
		width: 140px;
		height: 140px;
	}
	.image-error {
		i {
			font-size: 70px;
		}
	}
	.tool-wrapper {
		width: 20px;
		height: 20px;

		i {
			font-size: 14px;
		}
	}
}
.reply-file__img__small {
	max-width: 80px;
	// padding: 6px;

	.image-placeholder, .image-error {
		width: 80px;
		height: 80px;
	}
	.image-error {
		i {
			font-size: 40px;
		}
	}
	.tool-wrapper {
		width: 18px;
		height: 18px;

		i {
			font-size: 14px;
		}
	}
}
.reply-file__img__mini {
	max-width: 60px;
	// padding: 4px;

	.image-placeholder, .image-error {
		width: 60px;
		height: 60px;
	}
	.image-error {
		i {
			font-size: 30px;
		}
	}
	.tool-wrapper {
		width: 15px;
		height: 15px;

		i {
			font-size: 12px;
		}
	}
}
</style>
