<template>
	<div class="reply-content-wrapper">
		<div class="reply-top">
			<div class="sender">
				<div class="sender-avatar">
					<el-avatar> {{ abbreviation }} </el-avatar>
				</div>
				<div class="sender-info">
					<div class="sender-name"> {{ data.createBy }} </div>
					<template v-if="department">
						/
						<div class="sender-company"> {{ department }} </div>
					</template>
				</div>
			</div>
			<div class="reply-title-right">
				<!-- <span v-if="replyVisible" class="sub-reply" @click="subreply"> 回复 </span> -->
				<span class="sent-time">{{ (data.createTimeStr && $moment(data.createTimeStr).format('YYYY-MM-DD HH:mm:ss')) || '' }}</span>
			</div>
		</div>
		<div class="reply-content">
			<div class="ql-editor" v-html="data.content"></div>
			<div class="file-wrapper">
				<template v-for="(file, index) of data.sysAttachFileList">
					<ReplyImage
						v-if="isImage(file.fileName)"
						:key="replyId + '_' + index"
						:image="file"
						:previewList="previewList"
					></ReplyImage>
					<ReplyFile
						v-else
						:key="replyId + '_' + index"
						:file="file"
						size="large"
						action="download"
					></ReplyFile>
				</template>
			</div>
			<div class="toolbar-wrapper">
				<div v-if="replyVisible" class="tool" @click="subreply">
					<i class="el-icon-chat-dot-square"></i>
				</div>
				<div v-if="couldDelete" class="tool danger" @click="deleteReply">
					<i class="el-icon-delete"></i>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ReplyImage from './attachments/Image'
import ReplyFile from './attachments/File'
import "vue2-editor/dist/vue2-editor.css"
import "quill/dist/quill.core.css"
import "quill/dist/quill.bubble.css"
import "quill/dist/quill.snow.css"

export default {
	name: 'ReplyDisplay',
	components: {
		ReplyImage,
		ReplyFile
	},
	props: {
		data: {
			type: Object,
			default: () => {
				return {}
			}
		},
		// 显示回复图标
		showReply: {
			type: Boolean,
			default: true
		}
	},
	computed: {
		// 是否是超级管理员
		isSuperAdmin() {
			const userInfoStr = window.sessionStorage.getItem('userInfo')
			if (!userInfoStr) return false

			const userInfo = JSON.parse(userInfoStr)
			if (!userInfo) return false
			if (userInfo.superAdminFlag === '1') return true

			return false
		},
		replyId() {
			if (this.data && this.data.id) return this.data.id
			return ''
		},
		// 名称首字母
		abbreviation() {
			if (!this.data.createBy) return ''
			const word = this.data.createBy[0]
			return word.toUpperCase()
		},
		// 部门
		department() {
			if (!this.data.companyName) return ''
			return this.data.companyName
		},
		images() {
			if (!this.data.sysAttachFileList || this.data.sysAttachFileList.length === 0) return []

			const imgs = []
			for (const file of this.data.sysAttachFileList) {
				if (this.isImage(file.fileName)) {
					imgs.push(file)
				}
			}

			return imgs
		},
		previewList() {
			if (this.images.length === 0) return []

			const l = []
			for (const image of this.images) {
				l.push(image.fileAddr)
			}

			return l
		},
		// 是否是当前用户回复的数据
		isMyReply() {
			if (this.data) {
				const userInfoStr = window.sessionStorage.getItem('userInfo')
				if (!userInfoStr) return false

				const userInfo = JSON.parse(userInfoStr)
				if (!userInfo) return false

				const userId = userInfo.id
				if (userId == this.data.userId) return true
			}
			return false
		},
		// 是否是回复的回复
		isSubReply() {
			if (this.data && this.data.replyId) return true
			return false
		},
		replyVisible() {
			return this.showReply && !this.isMyReply && !this.isSubReply
		},
		couldDelete() {
			// return this.isMyReply || this.isSuperAdmin
			return this.isSuperAdmin
		}
	},
	data() {
		return {}
	},
	methods: {
		isImage(fileName) {
			const suffixList = ['png', 'jpg','jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff']
			if (fileName) {
				const index = fileName.lastIndexOf('.')
				if (index >= 0) {
					const suffix = fileName.substr(index + 1)
					return suffixList.indexOf(suffix) !== -1
				}
			}

			return false
		},
		// 回复的回复
		subreply() {
			this.$emit('subreply', this.data)
		},
		// 删除回复
		deleteReply() {
			this.$emit('deleteReply', this.data)
		}
	}
}
</script>

<style lang="scss" scoped>
.reply-content-wrapper {
	.reply-top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: rgba(17, 127, 252, 1);
		background-color: rgba(199, 227, 249, .55);
		padding: 12px 20px;

		.sender {
			display: flex;
			align-items: center;

			.sender-avatar {
				display: flex;
				align-items: center;
				margin-right: 10px;

				.el-avatar {
					width: 30px;
					height: 30px;
					line-height: 30px;
					font-size: 16px;
					font-weight: bold;
					color: rgba(17, 127, 252, 1);
					background-color: rgba(244, 249, 255, 1);
				}
			}
			.sender-info {
				display: flex;
				align-items: baseline;

				.sender-name {
					padding-right: 6px;
					font-weight: bold;
				}
				.sender-company {
					padding-left: 6px;
					font-size: 14px;
				}
			}
		}
		.reply-title-right {
			display: flex;
			align-items: center;

			.sub-reply {
				font-size: 12px;
				color: #eb6b80;
				margin-right: 10px;
				cursor: pointer;
			}
			.send-time {
				font-size: 14px;
			}
		}
	}
	.reply-content {
		background-color: rgba(246, 249, 253, .57);
		font-size: 12px;
		line-height: 18px;
		padding: 10px 20px;

		.file-wrapper {
			display: flex;
			flex-wrap: wrap;
			padding: 0 15px;

			* {
				margin-right: 10px;
			}
		}
		.toolbar-wrapper {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding: 6px 2px;

			.tool {
				font-size: 20px;
				color: rgba(17, 127, 252, 1);
				cursor: pointer;

				&:nth-child(n+2) {
					margin-left: 16px;
				}
				&.danger {
					color: rgba(233, 81, 94, 1);
				}
			}
		}
		.reply-content__body {
			padding: 10px 0;
			word-break: break-all;
		}
	}
}
.ql-editor {
	min-height: unset;
}
</style>
