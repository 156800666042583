<template>
	<div @click="focus" class="reply-wrapper">
		<div class="reply-attachments">
			<template v-for="(file, index) of files">
				<ReplyImage v-if="isImage(file.name)" :key="index" :image="file" :response="file.response" showProgress
					:percentage="file.percentage" action="remove" @remove="remove(file)"></ReplyImage>
				<ReplyFile v-else :key="index" :file="file" size="small" showProgress :percentage="file.percentage"
					action="remove" @remove="remove(file)"></ReplyFile>
			</template>
		</div>
		<!-- <div class="reply-content">
			<el-input ref="input" v-model="data" type="textarea" autosize resize="none" rows="10"
				@blur="data=data.trim()"></el-input>
		</div> -->
		<div class="file-select">
			<el-upload ref="upload" multiple :action="action" :headers="headers" :file-list="files"
				:show-file-list="false" :before-upload="handleBeforeUpload" :on-change="handleChange">
				<el-button type="primary" size="small" class="file-select-btn"> 添加附件 </el-button>
			</el-upload>
		</div>
	</div>
</template>

<script>
	import ReplyImage from './attachments/Image'
	import ReplyFile from './attachments/File'

	const defaultSettings = require('@/settings.js')
	const url = defaultSettings.hostName

	export default {
		name: 'ReplyEditor',
		components: {
			ReplyImage,
			ReplyFile
		},
		props: {
			// 附件ID列表
			fileIds: {
				type: Array,
				default: () => {
					return []
				}
			},
			// 输入内容
			// content: {
			// 	type: String,
			// 	required: true
			// }
		},
		computed: {
			action() {
				return url + "bsi/sysAttachFile/addFile"
			},
			headers() {
				const token = sessionStorage.getItem('token') || ''
				return {
					token: token
				}
			},
			successFiles() {
				const files = []
				for (const file of this.files) {
					if (file.response && file.response.code === '0' && file.response.data && file.response.data.fileAddr) {
						files.push(file.response)
					}
				}

				return files
			},
			// 是否是超级管理员
			isSuperAdmin() {
				const userInfoStr = window.sessionStorage.getItem('userInfo')
				if (!userInfoStr) return false

				const userInfo = JSON.parse(userInfoStr)
				if (!userInfo) return false
				if (userInfo.superAdminFlag === '1') return true

				return false
			},
		},
		watch: {
			data(val) {
				this.$emit('update:content', val)
			},
			files(val) {
				// console.log('files: ', val)
				this.fileIdList = []
				for (const file of val) {
					if (file.response && file.response.code === '0') {
						this.fileIdList.push(file.response.data.id)
					}
				}
			},
			successFiles(files) {
				// console.log('files: ', files)

			},
			fileIdList(val) {
				this.$emit('update:fileIds', val)
			}
		},
		data() {
			return {
				data: '',
				files: [],
				fileIdList: []
			}
		},
		methods: {
			focus() {
				// this.$refs['input'].focus()
			},
			handleChange(file, fileList) {
				this.files = fileList
			},
			remove(file) {
				this.files.splice(this.files.indexOf(file), 1)
			},
			isImage(fileName) {
				const suffixList = ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff']
				if (fileName) {
					const index = fileName.lastIndexOf('.')
					if (index >= 0) {
						const suffix = fileName.substr(index + 1)
						return suffixList.indexOf(suffix) !== -1
					}
				}

				return false
			},
			handleBeforeUpload(file) {
				if (this.isSuperAdmin) {
					// 超管单个文件大小限制1G
					const limitSize = file.size / 1024 / 1024 / 1024 <= 1
					if (!limitSize) {
						this.$message.warning('文件大小不能超过1G')
						return false
					}
				} else {
					// 非超管单个文件大小限制255M
					// 2022-07-31 应客户要求改为200M
					const limitSize = file.size / 1024 / 1024 <= 200
					if (!limitSize) {
						this.$message.warning('文件大小不能超过200M')
						return false
					}
				}
			},
		}
	}

</script>

<style lang="scss" scoped>
	.reply-wrapper {
		position: relative;
		border: 1px solid rgba(221, 221, 221, 1);
		border-radius: 4px;
		margin-bottom: 10px;
		padding: 10px;
		overflow: hidden;
		min-height: 10px;
		/deep/ .el-textarea__inner {
			border: none;
		}

		.reply-attachments {
			display: flex;
			flex-wrap: wrap;

			* {
				margin-right: 10px;
			}
		}

		.file-select {
			position: absolute;
			right: 0;
			bottom: 0;

			.file-select-btn {
				border: none;
				color: rgba(17, 127, 252, 1);
				background-color: rgba(17, 127, 252, .2);
				border-radius: 4px 0 4px 0;
			}
		}
	}

</style>
